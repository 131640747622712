export default class Observer {
    constructor() {
        this.listeners = {};
    }

    listen(cmd, fn) {
        var lc = this.listeners[cmd] || (this.listeners[cmd] = []);
        lc.push(fn);

        return () => (lc.indexOf(fn) >= 0) && lc.splice(lc.indexOf(fn), 1);
    }

    hasListeners(cmd) {
        return this.listeners[cmd] && this.listeners[cmd].length;
    }

    fire(cmd, args) {
        this.listeners[cmd] && this.listeners[cmd].map(x => x).forEach(l => l.apply(cmd, args));
    };
}