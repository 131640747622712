import moment from 'moment';
import 'moment-timezone';
import React, { Component } from 'react';
import { FetchParams } from '../../entities/Message';
import { saveChat } from '../../model/Model';
import Hub from "../../utils/Hub";
import { TextField } from '../Fields';
import './chatList.css';
import { NewChat } from './NewChat';

export default class ChatList extends Component {
    state = {
        loading: true,
        chatId: null,
        data: []
    }

    constructor(p) {
        super(p);

        this.buildItem = this.buildItem.bind(this);
    }

    componentDidMount(p) {
        this.unlisten = [];

        this.unlisten.push(Hub.onConnect(() => this.loadData()));

        this.unlisten.push(Hub.listen('Send', m => {
            var chat = this.state.data.find(x => x.chat.id == m.chatId);
            if (chat) {
                chat.last = m;
                requestAnimationFrame(_ => this.forceUpdate());
            }
        }));

        this.unlisten.push(Hub.onSaveChat(d => {
            var chat = d.chat;
            var cd = this.state.data.find(x => x.chat.id == chat.id);
            if (cd) {
                Object.apply(cd.chat, chat);
            } else {
                this.state.data.push(d);
            }

            this.forceUpdate();
        }));

        this.unlisten.push(Hub.onSaveChat(d => {
            debugger;
            var chat = d.chat;

            this.forceUpdate();
        }));

        this.unlisten.push(Hub.listen('LeaveChat', cid => {
            this.state.data.splice(this.state.data.findIndex(x => x.id == cid), 1);

            this.forceUpdate();
        }));

        this.onFindOrCreatePersonChat = this.onFindOrCreatePersonChat.bind(this);
        this.mapChat = this.mapChat.bind(this);
    }

    componentWillUnmount() {
        this.unlisten && this.unlisten.forEach(x => x());
    }

    loadData() {
        Hub.chatList(new FetchParams(null, 100, [0, 1, 2, 3, 4, 5, 6, 7])).then(chatList => {
            this.setState({
                loading: false,
                data: chatList.map(this.mapChat)
            });
        }, err => {
            debugger;
        });
    }

    mapChat(d) {
        d.member.readDate = moment.tz(d.member.lastRead, 'YYYY-MM-DDTHH:mm:ss', 'UTC').local().format('YYYY-MM-DDTHH:mm:ss');
        if (d.last && d.last.ts) {
            d.last.ts = moment.tz(d.last.ts, 'YYYY-MM-DDTHH:mm:ss', 'UTC').local().format('HH:mm:ss');
        }

        return d;
    }

    setChatId(chatId) {
        this.setState({ chatId });
    }

    onSelect(x) {
        this.setState({
            chatId: x.chat.id
        });

        this.props.onSelect(x);
    }

    buildItem(x, i) {
        var classNames = [];
        x.hasUnreaded && classNames.push('unreaded');
        (x.chat.id == this.state.chatId) && classNames.push('selected');

        return <div
            key={i}
            selected={this.state.chatId == x.chat.id}
            onClick={_ => this.onSelect(x)}
            className={classNames.join(' ')}>
            <div name="avatar" style={{ backgroundImage: `url(${x.avatarUrl})` }} />
            <div className="chat-list-item-text">
                <div>
                    <span name="displayName">{x.displayName}</span>
                    {x.last ? <span name="ts">{x.last.ts}</span> : null}
                </div>
                {x.last ? <span name="last">{x.last.text}</span> : null}
            </div>
        </div>;
    }

    onFindOrCreatePersonChat() {
        var newName = this.state.newChatName;
        if (newName && newName.trim()) {
            var args;
            if (parseInt(newName) == newName) {
                args = [parseInt(newName)];
            } else {
                args = [null, newName]
            }

            Hub.resolvePersonalChat(...args).then(x => {
                saveChat(x)
                this.state.data.push(this.mapChat(x));
                this.forceUpdate();
            });
        }
    }

    render() {
        if (window.outerWidth > 640 || !this.state.chatId) {
            return <div><div className="chat-list">
                {this.state.data.map(this.buildItem)}
            </div>
                <NewChat />
                <TextField placeholder="Contact name"
                    value={this.state.newChatName}
                    onChange={e => this.setState({ newChatName: e.target.value })}
                    trailingIcon={{
                        icon: 'person_search',
                        tabIndex: 0,
                        onClick: this.onFindOrCreatePersonChat
                    }} />
            </div>;
        } else {
            return [];
        }
    }
}