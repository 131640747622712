import { Button } from "@rmwc/button";
import { TextField } from "@rmwc/textfield";
import React, { Component } from 'react';
import Application from "../entities/Application";
import route from "../Route";
import Form from "./Form";
import List from "./List";

export default class ApplicationSettings extends Component {
    appId
    state = {
        entity: {}
    }

    constructor(p) {
        super(p);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.applyAppId = this.applyAppId.bind(this);
    }

    componentDidMount() {
        this.unlisten = route.listen(this.applyAppId);

        this.applyAppId();
    }

    applyAppId() {
        var appId = route.get('appId');
        if ((this.appId || 'new') == (appId || 'new') || !((appId >= 0) || (appId == 'new'))) {
            // return;
            appId = 0;
        }

        if (appId > 0) {
            this.appId = appId;
            Application.get(1).then(entity => this.setState({ entity }));
        } else {
            this.appId = 0;
            this.setState({ entity: new Application() });
        }
    }

    componentWillUnmount() {
        this.unlisten && this.unlisten();
    }

    onSubmit(e) {
        e.preventDefault();

        this.state.entity.save().then(entity => {
            if (this.appId) {
                if (this.appId == entity.id) {
                    this.setState({ entity });
                }
            } else {
                route.setState('appId', entity.id);
            }
        });

        return false;
    }

    onChangeField(e) {
        this.state.entity[e.target.name] = e.target.value;
        this.forceUpdate();
    }

    render() {
        return <form method="post" onSubmit={this.onSubmit} action="api/application">
            <TextField label="Name" name="name" value={this.state.entity.name || ''} onChange={this.onChangeField} />
            <TextField label="AuthUrl" name="authUrl" value={this.state.entity.authUrl || ''} onChange={this.onChangeField} />
            <TextField label="Hosting URL" name="hostingUrl" value={this.state.entity.hostingUrl || ''} />
            <Button>Save</Button>
        </form>;
    }
}

export class ApplicationList extends List {
    getTypeId() {
        return Application._typeId;
    }

    getForm() {
        return <ApplicationForm />;
    }
}

class ApplicationForm extends Form {
    getTypeId() {
        return Application._typeId;
    }
}