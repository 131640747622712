import Api, { buildTypeCRUD } from "../model/Api"
import Store from "../model/Store";
import { getAppId } from "../model/TokenStore";

const types = {};

export default class BaseEntity {
    static _typeId
    static fields = []
    id

    constructor(id) {
        if (id instanceof Number) {
            this.id = id;
            this.constructor.fields && this.constructor.fields.forEach((f, i) => {
                this[f.name] = arguments[i + 1];
            });
        } else if (id) {
            Object.assign(this, id);
        }
    }

    static registerType(type, typeId) {
        if (!typeId) {
            typeId = type._typeId;
        }

        types[typeId] = type;
        buildTypeCRUD(typeId);
    }

    static getType(typeId) {
        return types[typeId];
    }

    static list() {
        return this.getApi().list(this.getAppId());
    }

    static get(id) {
        if (id > 0) {
            return this.getApi().get(this.getAppId(), id).then(x => new this(x));
        } else {
            return Promise.resolve(new this());
        }
    }

    static delete(id) {
        return this.getApi().delete(this.getAppId(), id);
    }

    static getApi() {
        return Api[this._typeId];
    }

    static getAppId() {
        return getAppId();
    }

    save() {
        var t = this.constructor
        return t.getApi()[this.id ? 'update' : 'create'](t.getAppId(), this)
            .then(x => {
                var ent = new t(x);
                Store.get(t._typeId).save(ent);
                return ent;
            });
    }

    delete() {
        return this.constructor.delete(this.id);
    }
}