import React from 'react';
import { IconButton } from '@rmwc/icon-button';
import File from "../entities/File";
import { getAppId, getToken, getUrl } from '../model/TokenStore';
import { formatBytes } from '../utils/Utils';
import Form from "./Form";
import List from "./List";
import { buildApi, buildRest } from '../model/Api';

export class FileList extends List {
    constructor(p) {
        super(p);

        this.onFileInput = this.onFileInput.bind(this);
    }

    getTypeId() {
        return File._typeId;
    }

    getForm() {
        return <FileForm />;
    }

    onCreateClick() {
        this._fileInput.click();
    }

    onFileInput(e) {
        this._fileInput = e;
        if (e) {
            const clearInput = _ => e.value = '';
            e.addEventListener('change', e => {
                if (e.target.value) {
                    const formData = new FormData();
                    formData.append('file0', e.target.files[0]);

                    fetch(getUrl() + '/rest/' + getAppId() + '/File/Upload', {
                        method: 'PUT',
                        body: formData,
                        headers: {
                            'xauth': getToken()
                        }
                    }).then(x => x.json(), clearInput)
                        .then(clearInput, clearInput);
                }
            });
        }
    }

    buildTopBar() {
        var res = List.prototype.buildTopBar.apply(this, arguments);
        res.push(<input type="file" className="hidden" ref={this.onFileInput} />);
        return res;
    }

    buildValue(row, field) {
        switch (field.name) {
            case 'size':
                return formatBytes(row[field.name]);

            default:
                return row[field.name];
        }
    }

    buildRowCells(routePrefix, row) {
        var res = List.prototype.buildRowCells.apply(this, arguments);
        res.splice(0, 0, row.contentType.indexOf('image') >= 0 ?
            <img src={getUrl() + '/api/' + getAppId() + '/File/Preview?fileKey=' + row.fileKey} /> :
            <div />);
        return res;
    }
}

class FileForm extends Form {
    constructor(p) {
        super(p);

        this.onDownloadClick = this.onDownloadClick.bind(this);
    }

    getTypeId() {
        return File._typeId;
    }

    onDownloadClick(e) {
        e.preventDefault();

        buildApi(null, 'File/GetFileDownloadUrl?fileKey=' + this.state.entity.fileKey, 'GET')
            .then(url => window.open(url.replace('~', getUrl())));
    }

    buildTitle() {
        var res = Form.prototype.buildTitle.apply(this, arguments);
        res.push(
            <div className="f1" />,
            <IconButton icon="download" theme={['primary']} onClick={this.onDownloadClick} />);
        return res;
    }
}