var chats = JSON.parse(localStorage.getItem('chats')) || [],
    hub;

const onMessageRead = function (cid, mid) {
    var c = getChat(cid);
    if (c) {
        c.member.lastReaded = mid;

        saveChat(c);
    }
}

export const applyHub = function (_hub) {
    hub = _hub;
    hub.onMessageRead(onMessageRead);
    hub.onSaveChat(saveChat);

    hub.listen('ChatList', setChats);
    var cl = hub.chatList;
    hub.chatList = function () {
        var res = cl.apply(this, arguments);
        res.then(setChats);
        return res;
    }
}

export const getChats = function () {
    return chats;
};

export const getChat = function (id) {
    return chats.find(x => x.chat.id == id);
};

export const setChats = function (c) {
    if (c) {
        chats = c;
    }

    localStorage.setItem('chats', JSON.stringify(chats));
}

export const saveChat = function (config) {
    if (typeof config == typeof '') {
        config = {
            name: config
        };
    }

    if (!config.chat && config.name) {
        config = {
            chat: config
        };
    }

    var c = config.chat;
    c.name = c.name && c.name.trim();
    !c.type && c.type !== 0 && (c.type = 1); // group

    chats.push(config);
    setChats();

    return Promise.resolve(config);
}