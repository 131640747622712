import config from "../Config";

const defaultUrl = document.location.hostname == 'localhost' ? 'http://localhost:5005' : '';

const init = name => {
    var res = localStorage && localStorage.getItem(name);
    if (res) {
        try {
            res = JSON.parse(res);
        } catch (e) {
            res = null;
        }
    }

    if (!res) {
        res = {};
    }

    return res;
}

var appIdResolver;
export function setAppIdResolver(fn) {
    appIdResolver = fn;
}
export const getAppId = appId => {
    if (appId > 0 || appId === 0) {
        return appId;
    }

    if (appIdResolver) {
        appId = appIdResolver();
    }

    return appId >= 0 ? parseInt(appId) || 0 : appId;
}

var tokens = init('tokens'),
    urls = init('urls');

export const getToken = (appId) => tokens[getAppId(appId)];
export const setToken = (appId, token) => {
    // var appId = parseInt(token.split('_')[0]) || 0;
    tokens[appId] = token;
    localStorage && localStorage.setItem('tokens', JSON.stringify(tokens));
    return appId;
}

export const getUrl = (appId) => (urls[getAppId(appId)] || config.url || defaultUrl);
export const setUrl = (url, appId) => {
    urls[getAppId(appId)] = url;
    localStorage && localStorage.setItem('urls', JSON.stringify(urls));
}

export const clearTokens = () => localStorage.removeItem('tokens');
export const clearUrls = () => localStorage.removeItem('urls');