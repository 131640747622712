import freeice from 'freeice';
import Hub from './Hub';

/**
// client 1
var rc = new RTCPeerConnection(JSON.parse('[{"url":"stun:stun.voipbuster.com","urls":["stun:stun.voipbuster.com"]},{"url":"stun:stun2.l.google.com:19302","urls":["stun:stun2.l.google.com:19302"]}]'));
rc.ondatachannel = e => { debugger; };
rc.onicecandidate = e => { e.candidate && console.log('onicecandidate', e.candidate); debugger; };

var dc = await rc.createDataChannel('dc');
dc.onmessage = e => { debugger; };
dc.onopen = e => { debugger; };

var offer = await rc.createOffer();
console.log('offer', offer);
rc.setLocalDescription(offer);

// client 2
var rc = new RTCPeerConnection(JSON.parse('[{"url":"stun:stun.voipbuster.com","urls":["stun:stun.voipbuster.com"]},{"url":"stun:stun2.l.google.com:19302","urls":["stun:stun2.l.google.com:19302"]}]'));
var dc;
rc.ondatachannel = e => { dc = e.channel; debugger; };
rc.onicecandidate = e => { e.candidate && console.log('onicecandidate', e.candidate); debugger; };

rc.setRemoteDescription(offer); // from client 1
rc.addIceCandidate(ice); // from client 1

var answer = await rc.createAnswer();
console.log('answer', answer);
rc.setLocalDescription(answer);

// client 1
rc.setRemoteDescription(offer); // from client 2
rc.addIceCandidate(ice); // from client 2
/**/

/**/
const debugConnection = connection => {
    connection.oniceconnectionstatechange = e => { debugger; }
    connection.onconnectionstatechange = e => { debugger; }
    connection.onicegatheringstatechange = e => { debugger; }
    connection.onaddstream = e => {
        debugger;
    }
    connection.onsignalingstatechange = e => {
        debugger;
        if (connection.signalingState == 'stable') {
        }
    }
};
/**/

const getLocalStream = function (type) {
    var res,
        ret = new Promise(r => res = r),
        applyRes = x => x.active ? res(x) : (x.onactive = e => res(x)),
        gum;

    switch (type) {
        case 'screen':
            gum = navigator.mediaDevices.getDisplayMedia({
                audio: true,
                video: {
                    width: 1280,
                    height: 720,
                }
            });
            break;

        case 'camera':
            gum = navigator.mediaDevices.getUserMedia({
                audio: true,
                video: {
                    width: 1280,
                    height: 720,
                }
            });
            break;
    }

    if (gum) {
        gum.then(applyRes);

        gum.catch(e => {
            var fve = document.getElementById('fakevideo');
            if (!fve) {
                document.body.insertAdjacentHTML('beforeEnd', '<video id="fakevideo" style="width:0;height:0" autoplay controls width="250"><source src="/IMG_2656.MP4" type="video/mp4"></video>');
            }

            var fve = document.getElementById('fakevideo');
            return fve.captureStream();
        }).then(applyRes);
    }

    return ret;
}

const setStream = (stream, client) => {
    stream && stream.getTracks()
        .forEach(track => {
            var sender = client.connection.getSenders().find(x => x.track.kind == track.kind);
            if (sender) {
                debugger;
                sender.replaceTrack(track);
                sender.setStreams(stream);
            } else {
                client.connection.addTrack(track, stream);
            }
        });
}

export default function resolveWebRTC(chatId, cb) {
    const clients = [],
        connections = {};

    var localStream,
        localClient,
        joined;

    const setClient = (cfg) => {
        var client = clients.find(x => x.id == cfg.id);
        if (client) {
            Object.assign(client, cfg);
        } else {
            client = cfg;
            clients.push(cfg);
        }

        connections[client.id] = client;

        cb();

        return client;
    };

    const stopSdp = Hub.onSessionDescription(({ userId, sessionDescription: remoteDescription }) => {
        const connection = connections[userId].connection;
        remoteDescription = JSON.parse(remoteDescription);

        connection.setRemoteDescription(new RTCSessionDescription(remoteDescription))
            .then(() => {
                if (remoteDescription.type == 'offer') {
                    connection.createAnswer()
                        .then(answer => connection.setLocalDescription(answer)
                            .then(() => Hub.relaySDP(userId, JSON.stringify(connection.localDescription))
                                .then(() => cb())));
                }
            });
    });

    const stopIce = Hub.onIceCandidate(({ userId, iceCandidate }) => connections[userId].connection.addIceCandidate(new RTCIceCandidate(JSON.parse(iceCandidate))));

    const stopLeave = Hub.onLeaveStream(({ userId }) => {
        const index = clients.indexOf(5);
        if (index > -1) {
            clients.splice(index, 1);
        }

        delete connections[userId];

        cb();
    });

    const join = () => {
        if (!joined) {
            Hub.joinStream(chatId);
            joined = true;
        }
    }

    const start = type => getLocalStream(type)
        .then(ls => {
            if (localStream) {
                localStream.getTracks().forEach(track => track.stop());
                for (var id in connections) {
                    var c = connections[id];
                    if (c.stream !== localStream) {
                        // c.connection.removeStream(localStream);
                    }
                }

                localClient.stream = ls;
                cb();
            } else {
                localClient = setClient({
                    id: type,
                    local: true,
                    stream: ls
                });
            }

            localStream = ls;

            clients.filter(x => !x.local).forEach(client => {
                setStream(ls, client);
            });

            join(ls);

            cb();
        });

    const stop = () => {
        stopSdp();
        stopIce();
        stopLeave();

        localStream && localStream.getTracks().forEach(track => track.stop());
        localStream = null;

        Hub.leaveStream(chatId);

        for (var id in connections) {
            var c = connections[id];
            c.connection && c.connection.close();
            delete connections[id];
        }

        // clients.splice(localClient, 1);

        cb();
    };

    Hub.onJoinStream(({ userId, createOffer }) => {
        const connection = new RTCPeerConnection({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
            iceServers: freeice()
        }),
            client = setClient({
                id: userId,
                connection: connection
            });

        // debugConnection(connection);
        /** */
        setStream(localStream, client);

        connection.ontrack = e => {
            if (e.streams && e.streams[0]) {
                client.stream = e.streams[0];
                cb();
            }
        }

        connection.onremovestream = e => {
            client.stream = null;
            cb();
        };

        connection.onicecandidate = e => {
            if (e.candidate) {
                Hub.relayICE(userId, JSON.stringify(e.candidate));
            }
        }

        if (createOffer) {
            connection
                .createOffer()
                .then(offer => connection.setLocalDescription(offer)
                    .then(() => Hub.relaySDP(userId, JSON.stringify(offer))
                        .then(cb)));

        }
    });

    return {
        clients,
        start,
        stop,
        join
    };
}