import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';
import React, { Component } from 'react';
import { login } from '../model/Identity';
import { getAppId } from '../model/TokenStore';
import route from '../Route';
import './login.css';

export default class Login extends Component {
    constructor(p) {
        super(p);

        this.state = {
            appId: getAppId()
        };

        this.login = this.login.bind(this);
    }

    login(e, type) {
        e.preventDefault();

        var s = this.state;
        route.setState('appId', s.appId);
        login({
            authType: type || 'Login',
            appId: s.appId,
            authParams: {
                appId: s.appId,
                [s.appId && s.email.indexOf('@') < 0 ? 'clientId' : 'email']: s.email,
                password: s.password
            }
        })/**.then(x => window.location.reload())/**/;

        return false;
    }

    render() {
        return <div className="login-wrapper">
            <form method="post" onSubmit={this.login} action={this.props.action || 'auth/login'}>
                <h4>Intragram</h4>
                <hr />
                <TextField label="AppId (optional)" type="number" onChange={e => this.setState({ appId: parseInt(e.target.value) || 0 })} value={this.state.appId || ''} />
                <TextField label="Email" type="email" onChange={e => this.setState({ email: e.target.value })} value={this.state.email || ''} />
                <TextField label="Password" type="password" onChange={e => this.setState({ password: e.target.value })} value={this.state.password || ''} />
                <div>
                    <Button label="Войти" type="submit" unelevated onClick={this.login} />
                    <Button label="Зарегистироваться" onClick={e => this.login(e, 'Register')} />
                </div>
            </form>
        </div>;
    }
}