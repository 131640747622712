import Api, { getHeaders } from "./Api";
import errorManager from "./ErrorManager";
import Observer from "./Observer";
import Store from "./Store";
import { clearTokens, clearUrls, getAppId, getToken, getUrl, setToken, setUrl } from "./TokenStore";

var user;

const promiseReconnect = r => (r && Promise.reject(r));

const observer = new Observer();

const fireUserUpdate = () => observer.fire('update', [user]);

const applyUser = function (user) {
    if (user && user.isAuthenticated) {
        if (user.apps) {
            user.apps.forEach(x => setUrl(x.hostingUrl, x.id));
            if (!user.appId) {
                Store.get('Application').setData(user.apps);
            }
        }

        /** Why??
        var appId = route.get('appId');
        if (appId != user.appId) {
            return appin(appId).then(fireUserUpdate);            
        }
        /**/
    }

    return fireUserUpdate();
}

export const getUser = () => user;

export const isAuthentificated = () => (user && user.isAuthenticated);

export const isSuper = () => (isAuthentificated() && user.name == 'SYSTEM');

export const fetchUser = appId => Api.Auth.Current(appId)
    .then(x => {
        if (appId == getAppId()) {
            if (user) {
                Object.assign(user, x);
            } else {
                user = x;
            }
        }

        applyUser(x);
        return user;
    });

export const listenUser = fn => observer.listen('update', fn);

export const login = c => {
    var _c = Object.assign({}, c),
        appId = _c.appId || 0,
        authType = _c.authType || 'Login';

    return fetch(getUrl(appId) + '/api/' + appId + '/Auth/' + authType, {
        method: 'POST',
        headers: getHeaders(appId),
        body: JSON.stringify(_c.authParams)
    })
        .then(x => {
            return x.ok ? x.json() : x.json().then(promiseReconnect)
        }, r => promiseReconnect)
        .then(x => {
            user = x.result;
            if (user.xauth) {
                user.isAuthenticated = true;
                user.appId = appId;
                if (authType.toLowerCase().indexOf('token') < 0) {
                    setToken(appId, user.xauth);
                    return fetchUser(user.appId);
                }
            }

            return user;
        });
}

export const logout = c => {
    clearTokens();
    clearUrls();
    return fetch(getUrl(c.appId) + '/api/Auth/logout')
        .then(x => {
            window.location.reload();
            return x;
        });
}

export const appin = appId => login({ authType: 'AppIn', appId, authParams: getToken(0) });

errorManager.listen('SecurityException', (errFetch, err) => {
    // check master auth
    var appId = getAppId();
    if (appId) {
        return appin(appId).then(x => errFetch());
    }
});