import { listenUser } from "../model/Identity";
import Store from "../model/Store";
import BaseEntity from "./BaseEntity";

export default class Application extends BaseEntity {
    static _typeId = 'Application'
    static fields = [{
        name: 'name',
        title: 'Name'
    }, {
            name: 'authUrl',
            title: 'Check token url'
        }, {
            name: 'ownerId',
            title: 'Owner Id'
        }]

    name
    authUrl

    constructor(id, name, authUrl) {
        super(id);

        if (id instanceof Number) {
            this.name = name;
            this.authUrl = authUrl;
        }
    }
    /**
    static getAppId() {
        return 0;
    } */
}

listenUser(u => {
    if (u.apps) {
        Store.get('Application').setData(u.apps);
    }
});

BaseEntity.registerType(Application);