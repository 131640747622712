import React, { Component, isValidElement } from 'react';
import './field.css';

export class TextField extends Component {
    state = {
        value: null
    }

    constructor(p) {
        super(p);

        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(p) {
        this.setState(p);
    }

    onChange(e) {
        if ((this.props.onChange && this.props.onChange(e)) !== false) {
            this.setState({ value: e.target.value });
        }
    }

    buildIcon(c) {
        if (c && !isValidElement(c)) {
            return <button onClick={c.onClick}>
                <span className="material-icons">{c.icon}</span>
            </button>;
        }

        return c;
    }

    render() {
        var input;
        if (this.props.multiline) {
            input = <textarea {...this.props} onChange={this.onChange}>{this.state.value}</textarea>;
        } else {
            input = <input {...this.props} value={this.state.value || ''} onChange={this.onChange} />
        }

        return <div className="text-field">
            {this.buildIcon(this.props.startAdornment)}
            {input}
            {this.buildIcon(this.props.trailingIcon)}
        </div>;
    }
}