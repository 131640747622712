import { Button } from '@rmwc/button';
import { Drawer, DrawerAppContent, DrawerContent } from '@rmwc/drawer';
import { List, ListDivider, ListItem, ListItemGraphic } from '@rmwc/list';
import { MenuItem, SimpleMenu } from '@rmwc/menu';
import { TopAppBar, TopAppBarActionItem, TopAppBarFixedAdjust, TopAppBarNavigationIcon, TopAppBarRow, TopAppBarSection, TopAppBarTitle } from '@rmwc/top-app-bar';
import { Component } from 'react';
import './App.css';
import Overview, { ApplicationList } from './components/Application';
import { FileList } from './components/Files';
import Login from './components/Login';
import Messanger from './components/messenger/Messenger';
import UserList from './components/User';
import './form.css';
import { appin, getUser, isAuthentificated, isSuper, listenUser, logout } from './model/Identity';
import { ApplicationStore } from './model/Store';
import { getAppId, getToken } from './model/TokenStore';
import route from './Route';

const pageList = [
    ['Overview', () => true, () => getToken(0) ? <Overview key="Overview" /> : <div>...</div>],
    ['Statistics', appId => appId || isSuper(), () => []],
    ['Apps', appId => !appId || isSuper(), () => <ApplicationList key="ApplicationList" />],
    ['Users', appId => appId || isSuper(), () => <UserList key="UserList" />],
    ['Files', appId => true, () => <FileList key="FileList" />],
    //['Bots', appId => appId, () => <UserList key="UserList" />],
    ['Chat', () => true, () => <Messanger key="Chat" />]
    //,['Security', appId => appId, () => []]
];

const pages = {}; 
pageList.forEach(x => pages[x[0].toLowerCase()] = x);

export default class App extends Component {
    unlisten
    state = {
        open: true,
        appId: 0
    }

    constructor() {
        super();

        this.unlisten = [];

        this.state = Object.assign({}, this.state, {
            page: route.get('page'),
            appId: route.get('appId')
        });

        this.onMenuClick = this.onMenuClick.bind(this);
        this.onAppSelect = this.onAppSelect.bind(this);
        this.onUser = this.onUser.bind(this);
    }

    componentDidMount() {
        this.unlisten.push(listenUser(this.onUser));
        this.unlisten.push(route.listen(r => this.setState({ page: r.get('page'), appId: r.get('appId') })));

        this.appStore = ApplicationStore.get(0, 'Application', true);
        this.unlisten.push(this.appStore.listen('load', apps => this.setState({ apps })));

        var appsListener = this.appStore.listen('load', apps => {
            var appId = getAppId();
            if (!appId) {
                if (apps && apps.length) {
                    route.setState('appId', apps[0].id);
                }
            } else {
                if (isAuthentificated() && getUser().appId != appId) {
                    if (!apps || !apps.find(x => x.id == appId)) {
                        route.setState('appId', apps && apps.length && apps[0].id);
                    }
                }
            }

            appsListener();
        });
    }

    componentWillUnmount() {
        this.unlisten && this.unlisten.forEach(x => x());
    }


    appin(appId) {
        if (isAuthentificated()) {
            var token = getToken(appId);
            if (!token) {
                return appin(appId);
            }
        }

        return Promise.resolve();
    }

    onUser() {
        if (route.get('appId')) {
            this.appin(route.get('appId'));
        }

        this.forceUpdate();
    }

    getAppName() {
        var appId = this.state.appId;
        if (appId == 'new') {
            return <Button unelevated icon="add">New Application</Button>;
        } else if (appId = parseInt(appId)) {
            return <Button unelevated>{this.state.apps.filter(x => x.id === appId).map(x => x.name)[0]}</Button>;
        } else {
            return <Button unelevated icon="home">Home</Button>;
        }
    }

    buildApplicationsMenu() {
        if (this.state.apps) {
            if (this.state.apps.length) {
                var user = getUser();
                return <SimpleMenu
                    handle={this.getAppName()}
                    onSelect={this.onAppSelect}>
                    <MenuItem><ListItemGraphic icon="home" /> Home</MenuItem>
                    <ListDivider />
                    {this.state.apps.filter(x => x.ownerId == user.id).map(x => <MenuItem selected={this.state.appId == x.id}>{x.name}</MenuItem>)}
                    <ListDivider />
                    <MenuItem><ListItemGraphic icon="add" /> Create application</MenuItem>
                </SimpleMenu>;
            } else {
                return <Button icon="add" outlined unelevated onClick={this.onAppSelect}>Create application</Button>
            }
        } else {
            return 'Loading apps...';
        }
    }

    onAppSelect(e) {
        var app = e && this.state.apps[e.detail.index - 1];
        if (app) {
            this.appin(app.id).then(x => route.setState('appId', app.id));
        } else {
            route.setState([['page'], ['appId', e.detail.index ? 'new' : 0]]);
        }
    }

    onMenuClick(e) {
        route.setState([
            ['page', e.target.innerText.toLowerCase()],
            ['id']
        ]);
    }

    buildPages() {
        var appId = getAppId(),
            disabled = !(appId > 0),
            page = this.state.page.toLowerCase();

        appId = appId > 0 ? appId : 0;

        return pageList
            .filter(x => x[1](appId))
            .map(x => <ListItem key={x[0]} onClick={this.onMenuClick} selected={page == x[0].toLowerCase()}>{x[0]}</ListItem>);
    }

    getPage() {
        return pages[this.state.page.toLowerCase()][2]();
    }

    render() {
        switch (isAuthentificated()) {
            case false: return <Login />;
            case true: return <>
                <TopAppBar>
                    <TopAppBarRow>
                        <TopAppBarSection alignStart>
                            <TopAppBarNavigationIcon icon="menu" onClick={() => this.setState({ open: !this.state.open })} />
                            <TopAppBarTitle>Intragram</TopAppBarTitle>
                            <div className="v-separator" />
                            {this.buildApplicationsMenu()}
                        </TopAppBarSection>
                        <TopAppBarSection alignEnd>
                            <TopAppBarActionItem icon="person" />
                            <TopAppBarActionItem icon="logout" onClick={logout} />
                        </TopAppBarSection>
                    </TopAppBarRow>
                </TopAppBar>
                <TopAppBarFixedAdjust />

                <div className="f1 d-flex" style={{ position: 'relative' }}>
                    <Drawer dismissible open={this.state.open}>
                        <DrawerContent>
                            <List>
                                {this.buildPages()}
                            </List>
                        </DrawerContent>
                    </Drawer>

                    <DrawerAppContent className="f1 d-flex">
                        {this.getPage()}
                    </DrawerAppContent>
                </div>
            </>;

            default: return 'Loading...';
        }
    }
}