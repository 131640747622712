import { Component } from 'react';
import { getAppId } from '../model/TokenStore';
import route from "../Route";

export default class AppIdComponent extends Component {
    appId
    unlisten

    constructor(p) {
        super(p);

        this.unlisten = [];

        this.onRoute = this.onRoute.bind(this);
    }

    componentDidMount() {
        this.unlisten = [
            route.listen(this.onRoute)
        ];

        this.onRoute();
    }

    componentWillUnmount() {
        this.unlisten && this.unlisten.forEach(x => x());
    }

    onRoute() {
        var appId = getAppId();
        if (this.appId === appId) {
            return;
        } else {
            this.appId = appId;
            this.applyAppId();
        }
    }

    applyAppId() {
    }
}