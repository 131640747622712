import Observer from "./Observer";

const applyCmd = cmd => (cmd || '_');

class ErrorManager extends Observer {
    listen(cmd, fn) {
        return Observer.prototype.listen.call(this, applyCmd(cmd), fn);
    }

    hasListeners(cmd) {
        return Observer.prototype.hasListeners.call(this, applyCmd(cmd));
    }

    fire(cmd, args) {
        return Observer.prototype.fire.call(this, applyCmd(cmd), args);
    }

    fireErrors(fetch, errors) {
        return errors
            .map(x => this.fire(x.type, [fetch, x]))
            .find(x => x);
    }
}

const errorManager = new ErrorManager();
export default errorManager;