const objt = typeof {},
    fnt = typeof function () { },
    applyThis = function (_this, obj) {
        for (var f in obj) {
            if (typeof obj[f] != fnt) {
                _this[f] = obj[f];
            }
        }
    };

export class Message {
    /** @type {BigInt} /**/
    id=0
    /** @type {String} /**/
    text=''
    /** @type {Date} /**/
    ts
    /** @type {BigInt} /**/
    chatId

    /**
     * @param {String} text
     * @param {BigInt} chatId
     */
    constructor(text, chatId) {
        this.chatId = chatId;

        if (text) {
            if (typeof text === typeof '') {
                this.text = text;
            } else if (typeof text === objt) {
                applyThis(this, text);
            }
        }
    }
};

export class Attachment {
    id
    title = ''
    type = 0
    contentType = ''
    provider = ''
    fileKey = ''
    size = 0

    /**
     * @param {String} title
     * @param {String} type
     */
    constructor(title, type) {
        this.type = type;

        if (title) {
            if (typeof title === typeof '') {
                this.title = title;
            } else if (typeof title === objt) {
                applyThis(this, title);
            }
        }
    }
};

export default class MessageDto {
    /** @type {Message} /**/
    message
    /** @type {{[Attachment]}} /**/
    attachment

    /**
     * @param {Message} message
     * @param {[Attachment]} attachments
     */
    constructor(message, attachment) {
        this.message = message;
        this.attachment = attachment;
    }
}

export class MessageSenderDto extends MessageDto {
    sender

    /**
     * @param {Message} message
     * @param {[Attachment]} attachments
     */
    constructor(message, attachment, sender) {
        super(message, attachment)
        this.sender = sender;
    }
}

export class FetchParams {
    /** @type {string} /**/
    query
    /** @type {Int8Array} /**/
    types
    /** @type {number} /**/
    limit

    /**
     * @param {string} message
     * @param {Int8Array} limit
     * @param {number} types
     */
    constructor(query, limit, types) {
        this.query = query;
        this.limit = limit;
        this.types = types;
    }
}

window.Intragram = window.Intragram || {};
window.Intragram.Attachment = Attachment;
window.Intragram.MessageDto = MessageDto;
window.Intragram.Message = Message;
window.Intragram.MessageSenderDto = MessageSenderDto;
window.Intragram.FetchParams = FetchParams;