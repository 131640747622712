import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';
import { Typography } from '@rmwc/typography';
import React from 'react';
import BaseEntity from '../entities/BaseEntity';
import route from "../Route";
import AppIdComponent from './AppIdComponent';

export default class Form extends AppIdComponent {
    _type

    constructor(p) {
        super(p);

        this.state = {
            entity: {}
        };

        this.buildField = this.buildField.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.applyType();
    }

    isNew() {
        return !(route.get('id') > 0);
    }

    isUnupdatable(f) {
        return false;
    }

    getTypeId() {
        return this.props.typeId;
    }

    applyType() {
        this._type = BaseEntity.getType(this.getTypeId());
    }

    onRoute() {
        AppIdComponent.prototype.onRoute.apply(this, arguments);

        if (this.id != route.get('id')) {
            this.id = route.get('id');
            this.applyAppId();
        }
    }

    applyAppId() {
        this._type.get(route.get('id')).then(entity => this.setState({ entity }));
    }

    buildField(x) {
        var unupdatable = !this.isNew() && this.isUnupdatable(x);
        return <TextField
            key={x.name}
            name={x.name}
            label={x.title}
            value={this.state.entity[x.name] || ''}
            outlined={!unupdatable}
            onChange={unupdatable ? null : this.onChangeField} />;
    }

    onChangeField(e) {
        this.state.entity[e.target.name] = e.target.value;
        this.forceUpdate();
    }

    onSubmit(e) {
        e.preventDefault();

        this.state.entity.save().then(entity => {
            if (this.id) {
                if (this.id == entity.id) {
                    this.setState({ entity });
                }
            } else {
                route.setState('id', entity.id);
            }
        });

        return false;
    }

    onDelete(e) {
        e.preventDefault();

        this.state.entity.delete().then(res => {
            if (res) {
                route.setState('id');
            }
        });

        return false;
    }

    buildTitle() {
        return [
            this.isNew() ? 'New ' + this.getTypeId() : (this.getTypeId() + ' [' + route.get('id') + ']')
        ];
    }

    render() {
        return <form onSubmit={this.onSubmit}>
            <Typography use="headline5" className="title">
                {this.buildTitle()}
            </Typography>
            {this._type.fields.map(this.buildField)}
            <div className="button-bar">
                {this.isNew() ? null : <Button label="Delete" raised danger onClick={this.onDelete} />}
                <div className="f1" />
                <Button label="Cancel" outlined onClick={e => route.setState('id')} />
                <Button label={this.isNew() ? 'Create' : 'Update'} raised />
            </div>
        </form>;
    }
}