import BaseEntity from "./BaseEntity";

export default class File extends BaseEntity {
    static _typeId = 'File'
    static fields = [{
        name: 'name',
        title: 'Name'
    }, {
        name: 'size',
        title: 'Size'
    }]

    name
}

BaseEntity.registerType(File);