function onPushState(callback) {
    (function (pushState) {
        window.history.pushState = function () {
            pushState.apply(this, arguments);
            callback.apply(window, arguments);
        };
    })(window.history.pushState);
}

class UrlPathRoute {
    prefix
    paths = []

    constructor(prefix) {
        this.prefix = prefix || '';
    }

    addUrlPath(path) {
        this.paths.push(path);
        if (this._baseUrl) {
            console.warn('Configure Route baseUrl before add url paths!');
        }

        return this;
    }
}

class Route {
    _defaultPathRoute
    _listeners = []
    _routes = {}
    _pathRoutes = []
    _baseUrl = ''

    constructor() {
        this._defaultPathRoute = new UrlPathRoute('');

        onPushState(window.onhashchange = this.hashChanged.bind(this));
        window.onpopstate = this.hashChanged.bind(this);
        this.hashChanged();
    }

    listenDocument() {
        if (!this.documentListen) {
            var me = this;

            document.addEventListener('click', function (e) {
                var el = e.target;
                while (el && !el.hasAttribute('data-route')) {
                    el = el.parentElement;
                }

                if (el) {
                    var route = el.getAttribute('data-route');
                    if (route && route.indexOf('=') >= 0) {
                        me.setState((route.indexOf('&') >= 0 ? route.split('&') : [route]).map(x => x.split('=')));
                    } else if (el.href) {
                        window.history.pushState({}, document.title, el.href);
                    } else {
                        return;
                    }

                    e.preventDefault();
                }
            });

            this.documentListen = true;
        }
    }

    setBaseUrl(baseUrl) {
        if (baseUrl && baseUrl[baseUrl.length - 1] === '/') {
            baseUrl = baseUrl.substr(0, baseUrl.length - 1);
        }

        this._baseUrl = baseUrl;
    }

    addRoute(prefix) {
        var route = new UrlPathRoute(prefix);
        this._pathRoutes.push(route);
        return route;
    }

    get(part) {
        var value = this[part];
        if (value === null || value == undefined) {
            var reg = this._routes[part];
            if (reg && reg.defaultFn) {
                value = reg.defaultFn();
            }
        }

        if (value === null || value == undefined) {
            value = this.getParameterByName(part, true);
        }

        return value;
    }

    setState(part, value, silent) {
        if (!Array.isArray(part)) {
            part = [[part, value]];
        }

        var i = 0;
        for (; i < part.length; i++) {
            if (JSON.stringify(this[part[i][0]]) !== JSON.stringify(part[i][1])) {
                break;
            }
        }

        if (i === part.length) {
            return;
        }

        for (; i < part.length; i++) {
            this[part[i][0]] = part[i][1];
        }

        this.updatePath();

        if (silent !== true) {
            this.fireChange();
        }
    }

    fireChange() {
        if (this._listeners) {
            this._listeners.map(x => x(this));
        }
    }

    _getPathRoute() {
        var prefix = this.prefix || '';
        return this._pathRoutes.find(x => x.prefix === prefix) || this._defaultPathRoute;
    }

    updatePath() {
        var params = [],
            pathRoute = this._getPathRoute(),
            paths = new Array(pathRoute.paths.length),
            hasEmptyPath,
            routePart,
            value;

        for (var part in this._routes) {
            routePart = this._routes[part];
            value = this[part];

            if (!value && routePart.defaultFn) {
                value = routePart.defaultFn();
            }

            if (routePart.encoder) {
                value = routePart.encoder(value);
            }

            if (value) {
                var pathIndex = pathRoute.paths.indexOf(part);
                if (pathIndex >= 0) {
                    paths[pathIndex] = value;
                } else {
                    params.push(part + '=' + value);
                }
            }
        }

        for (var i = paths.length - 1; i >= 0; i--) {
            if (hasEmptyPath === false) {
                if (!paths[i] && paths[i] !== false) {
                    hasEmptyPath = true;
                    break;
                }
            } else {
                if (paths[i] || paths[i] === false) {
                    hasEmptyPath = false;
                } else {
                    paths.length--;
                }
            }
        }

        if (hasEmptyPath) {
            for (i = 0; i < paths.length; i++) {
                if (paths[i] || paths[i] === false) {
                    params.push(pathRoute.paths[i] + '=' + paths[i]);
                }
            }

            paths = [];
        }

        this.setPath(params.join('&'), paths.join('/'));
    }

    setPath(params, path) {
        if (params.length && params[0] !== '?') {
            params = '?' + params;
        }

        if (!path) {
            path = '/';
        } else if (path[0] !== '/') {
            path = '/' + path;
        }

        var url = this._baseUrl + (this.prefix ? '/' + this.prefix : '') + path + params;
        if (window.location.href === url) {
            return;
        }

        window.history.pushState({}, document.title, url);
    }

    _updatePrefix() {
        this.prefix = this._pathRoutes
            .filter(x => window.location.pathname.substr(1).indexOf(x.prefix) === 0)
            .map(x => x.prefix)[0] || '';
    }

    hashChanged() {
        this._updatePrefix();

        for (var part in this._routes) {
            this[part] = this.getParameterByName(part, true);
        }

        this.fireChange();
    }

    getParameterByName(name, silent) {
        this._updatePrefix();

        name = name.replace(new RegExp('[\\[\\]]', 'g'), "\\$&");

        var pathRoute = this._getPathRoute(),
            pathIndex = pathRoute.paths.indexOf(name),
            result;
        if (pathIndex >= 0) {
            result = window.location.pathname.substr((pathRoute.prefix.length || -1) + 2).split('/')[pathIndex];
        }

        if (!result && result !== false) {
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(window.location.href);
            if (!results) return null;
            if (!results[2]) return '';

            result = decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        if (this._routes[name] && this._routes[name].decoder) {
            result = this._routes[name].decoder(result);
        }

        if (silent !== true) {
            this.setState(name, result);
        }

        return result;
    }

    listen(listener, _this) {
        var l = _this ? listener.bind(_this) : listener;

        this._listeners.push(l);

        return () => (this._listeners = this._listeners.filter(x => x != l));
    }

    register(path, defaultFn, encoder, decoder) {
        this._routes[path] = {
            path: path,
            defaultFn: defaultFn,
            encoder: encoder,
            decoder: decoder
        };

        var value = this.getParameterByName(path, true);
        if (!value && value !== false && defaultFn) {
            value = defaultFn();
        }

        this[path] = value;
    }
};

var route = new Route();
export default route;