import React from 'react';
import User from '../entities/User';
import Form from './Form';
import List from './List';

const searchFields = ['name', 'clientId', 'avatarUrl'];

export default class UserList extends List {
    getTypeId() {
        return User._typeId;
    }

    getSearchFields() {
        return searchFields;
    }

    getForm() {
        return <UserForm />;
    }
}

class UserForm extends Form {
    static unUpdatableFields = [
        'clientId'
    ]

    getTypeId() {
        return User._typeId;
    }
}