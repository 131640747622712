import { Button } from "@rmwc/button";
import { IconButton } from "@rmwc/icon-button";
import { Icon } from "@rmwc/icon";
import { SimpleMenu, MenuItem } from "@rmwc/menu";
import moment from 'moment';
import 'moment-timezone';
import React, { Component } from 'react';
import MessageDto, { Message } from '../../entities/Message';
import { getChat } from "../../model/Model";
import { getToken } from "../../model/TokenStore";
import Hub from "../../utils/Hub";
import resolveWebRTC from "../../utils/WebRTC";
import { TextField } from "../Fields";

export default class Chat extends Component {
    state = {
        loading: true,
        data: []
    }

    constructor(p) {
        super(p);

        this.buildMessageRow = this.buildMessageRow.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.attach = this.attach.bind(this);
        this.onAttachInput = this.onAttachInput.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onMessage(m) {
        m = this.buildVM(m);

        if (this.props.chatId == m.chatId) {
            var indx = this.state.data.findIndex(x => x.id == m.id);
            if (indx >= 0) {
                this.state.data.splice(indx, 1, m);
            } else {
                this.state.data.push(m);
            }

            this.forceUpdate();

            this.scrollBottom();
        }
    }

    componentDidMount() {
        Hub.onMessage(this.onMessage.bind(this));
        Hub.onMessageUpdated(this.onMessage.bind(this));
        Hub.onMessageDeleted(mid => {
            var indx = this.state.data.findIndex(x => x.id == mid);
            if (indx >= 0) {
                this.state.data.splice(indx, 1);

                this.forceUpdate();
            }
        });

        this._onMessageRead = Hub.onMessageRead((cid, mid) => {
            if (this.props.chatId == cid) {
                this.state.data.filter(x => !x.readTs && x.id <= mid)
                    .forEach(x => x.readTs = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss', 'UTC'))
                this.forceUpdate();
            }
        });

        this._onMessageReceived = Hub.onMessageReceived((cid, mid) => {
            if (this.props.chatId == cid) {
                this.state.data.filter(x => !x.deliveredTs && x.id <= mid)
                    .forEach(x => x.deliveredTs = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss', 'UTC'))
                this.forceUpdate();
            }
        });

        this.loadData();

        this.wrtc = resolveWebRTC(this.props.chatId, x => this.forceUpdate());
    }

    componentWillUnmount() {
        this._onMessageRead && this._onMessageRead();
        this._onMessageReceived && this._onMessageReceived();
        this.wrtc && this.wrtc.stop();
    }

    buildVM(mdto) {
        var d = mdto instanceof MessageDto ? mdto.message : mdto;

        d.dt = d.ts;
        d.ts = moment.tz(d.ts, 'YYYY-MM-DDTHH:mm:ss', 'UTC').local().format('HH:mm:ss');
        d.readDate = d.readDate && moment.tz(d.readTs, 'YYYY-MM-DDTHH:mm:ss', 'UTC').local().format('YYYY-MM-DDTHH:mm:ss');
        if (!d.attachment) {
            d.attachment = mdto.attachment;
        }

        return d;
    }

    loadData() {
        this.setState({ loading: true });

        this._chat = getChat(this.props.chatId);

        Hub.messageList(this.props.chatId, this.state.data.length ? this.state.data.map(x => x.id).sort((x, y) => x > y ? -1 : 1)[0] : null)
            .then(x => {
                x.messages.sort((x, y) => x.ts > y.ts ? 1 : -1)
                    .map(d => {
                        this.state.data.push(this.buildVM(new MessageDto(d.message, d.attachment)));
                        return d;
                    });


                this.setState({
                    loading: false
                });

                if (x.length) {
                    this.scrollBottom();
                }
            });
    }

    scrollBottom() {
        if (this._listEl) {
            this._listEl.scrollTo(0, 99999999);
            this.onListScroll({ target: this._listEl });
        }
    }

    sendMessage() {
        var m = this.state.message;
        if (!this._attachment) {
            if (!m || !(m = m.trim())) {
                return;
            }
        }

        var message = new MessageDto(new Message(this.state.message, this.props.chatId), this._attachment);
        if (this.state.messageId) {
            message.message.id = this.state.messageId;
        }

        Hub[this.state.messageId ? 'updateMessage' : 'sendMessage'](message)
            .then(_ => {
                this.setState({
                    message: null,
                    messageId: null
                });
            });
    }

    attach() {
        if (this._attachInput) {
            this._attachInput.value = '';
            this._attachInput.click();
        }
    }

    onAttachInput(e) {
        this._attachInput = e;
        if (e) {
            e.addEventListener('change', e => {
                if (e.target.value) {
                    Hub.getFileUploadUrl().then(url => {
                        const formData = new FormData();
                        formData.append('file0', e.target.files[0]);

                        fetch(url, {
                            method: 'PUT',
                            body: formData,
                            headers: {
                                'xauth': getToken()
                            }
                        }).then(x => x.json())
                            .then(fi => {
                                e.target.value = '';

                                if (fi && fi.result) {
                                    this._attachment = fi.result;

                                    this._attachment.title = this._attachment.name;
                                }
                            });
                    });
                }
            });
        }
    }

    toggleStream(type) {
        if (this.wrtc) {/**
            var client = this.wrtc.clients.find(x => x.stream && x.local && x.id === type);
            if (client) {
                this.wrtc.stop(type);
            } else {/**/
                this.wrtc.start(type);
            /**}/**/
        }
    }

    onStartVideo() {
        this.toggleStream('camera');
        /**
        this.wrtc = resolveWebRTC(this.props.chatId, x => this.forceUpdate());
        this.setState({
            video: true
        });
         */
    }

    onStartCapture() {
        this.toggleStream('screen');
        /**
        this.wrtc = resolveWebRTC(this.props.chatId, x => this.forceUpdate(), 'screen');
        this.setState({
            video: true
        });
         */
    }

    onListRef(le) {
        this._listEl = le;
    }

    onListScroll(e) {
        var t = e.target,
            ltb = [t.scrollTop + t.offsetTop];

        ltb.push(ltb[0] + t.offsetHeight);

        var unreaded = [];
        t.childNodes.forEach(r => {
            if (r.offsetTop > ltb[0] &&
                (r.offsetTop + r.offsetHeight) < ltb[1] &&
                r.classList.contains('unreaded') &&
                !r.classList.contains('message-self')) {
                unreaded.push(parseInt(r.getAttribute('data-id')));
            }
        });

        if (!document.hidden && unreaded.length) {
            Hub.read(this.props.chatId, unreaded[unreaded.length - 1])
                .then(c => {
                    this._chat.member.lastReaded = unreaded[unreaded.length - 1] || 0;
                    this.forceUpdate();
                });
        }
    }

    getFileDownloadUrl(att, fn) {
        return Hub.getFileDownloadUrl(att.provider, att.fileKey, att.name).then(fn);
    }

    downloadFile(att) {
        return this.getFileDownloadUrl(att, url => window.open(url.replace('~', Hub.endpoint)))
    }

    buildMessageRow(x) {
        var cn = ['message-row'],
            mem = this._chat.member;
        if (x.senderId == mem.userId) {
            cn.push('message-self');
        } else if ((mem.lastReaded || 0) < x.id) {
            cn.push('unreaded');
        }

        var card = <div className="message">
            {x.attachment ? <div className="img">
                {x.attachment.thumbnailUrl ?
                    <><img
                        src={x.attachment.thumbnailUrl.replace('~', Hub.endpoint)}
                        onClick={() => this.getFileDownloadUrl(x.attachment, url => this.setState({ viewAttachment: { attachment: x.attachment, url } }))}
                        title={x.attachment.title} />
                        <span className="img-title">{x.attachment.title}</span>
                    </> :
                    <Button
                        icon="attach_file"
                        onClick={() => this.downloadFile(x.attachment)}>{x.attachment.title}</Button>}
            </div> : null}
            {x.text ? x.text.split('\n').map((x, i) => i > 0 ? [<br key={'br' + i} />, x] : x) : x.text}
            <span key="dt">{x.ts}</span>
        </div>;

        if (!x.isDeleted && x.senderId == mem.userId && (x.readTs || x.deliveredTs)) {
            var onClick;
            if (this.state.messageId == x.id) {
                cn.push('mdc-list-item mdc-list-item--selected');
                onClick = e => this.setState({ message: '', messageId: null });
            } else {
                onClick = e => this.setState({ message: x.text, messageId: x.id });
            }

            card = <div className="status-wrapper">
                <Button icon="edit" onClick={onClick} />
                <Button icon="delete" onClick={e => Hub.deleteMessage(x.chatId, x.id)} />
                {card}
                {x.deliveredTs ? <Icon icon="done" /> : null}
                {x.readTs ? <Icon icon="done" /> : null}
            </div>;
        }

        return <div key={'mes' + x.id} data-id={x.id} className={cn.join(' ')}>
            {card}
            {x.readDate ? null : <div />}
        </div>
    }

    buildAttachmentView() {
        if (this.state.viewAttachment) {
            return <div className="win attachment-win">
                <div className="win-body">
                    <img src={this.state.viewAttachment.url.replace('~', Hub.endpoint)} />
                </div>
                <div>
                    <Button onClick={() => this.downloadFile(this.state.viewAttachment.attachment)}>Download</Button>
                    <Button onClick={() => this.setState({ viewAttachment: null })}>Close</Button>
                </div>
            </div>;
        }
    }

    buildStreams() {
        if (this.wrtc && this.wrtc.clients && this.wrtc.clients.length) {
            return <div className="streams-wrapper">
                {this.wrtc.clients
                    .filter(x => x.stream)
                    .map((client, i) => <video
                        key={client.id}
                        name={client.id}
                        onClick={e => {
                            e.target.classList.toggle('full-screen-element');
                            e.target.parentElement.classList.toggle('full-screen');
                        }}
                        autoPlay
                        playsInline
                        muted={client.local}
                        className={client.local ? 'local-stream' : null}
                        ref={e => e && (e.srcObject = client.stream)}
                    />)}
            </div>;
        }
    }

    onClose() {
        if (!this.props.onClose || (this.props.onClose() !== false)) {
            this.setState({ chatId: null });
        }
    }

    render() {
        if (!this._chat) {
            return <></>;
        }

        return <div className="d-flex f1 flex-col">
            <div className="chat-toolbar">
                <Button icon="chevron_left" onClick={this.onClose} />
                <h4>{this._chat.displayName}</h4>
                <div className="f1 "/>
                <Button label="video" icon="videocam" onClick={this.onStartVideo.bind(this)} />
                <Button label="screen" icon="desktop_windows" onClick={this.onStartCapture.bind(this)} />
            </div>

            <div className="d-flex f1 flex-row">
                <div className="chat">
                    <div className="message-list" ref={this.onListRef.bind(this)} onScroll={this.onListScroll.bind(this)}>
                        {this.state.data.map(this.buildMessageRow )}
                    </div>
                    
                    <TextField
                        multiline={true}
                        className="message-input"
                        onKeyPress={e => {
                            if (e.which == 13 && !e.shiftKey) {
                                this.sendMessage();
                            }
                        }}
                        startAdornment={<SimpleMenu handle={<IconButton icon="attach_file" />}>
                            <MenuItem onClick={this.attach}>
                                <input type="file" className="hidden" ref={this.onAttachInput} />
                                Upload
                            </MenuItem>
                            <MenuItem>Library</MenuItem>
                        </SimpleMenu>}
                        trailingIcon={{
                            icon: "send",
                            onClick: this.sendMessage
                        }}
                        onChange={e => this.setState({ message: e.target.value })}
                        value={this.state.message || ''} />
                </div>

                {this.buildStreams()}
            </div>

            {this.buildAttachmentView()}
        </div>;
    }
}