import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import config from '../Config';
import MessageDto, { Attachment, FetchParams, Message, MessageSenderDto } from '../entities/Message';
import { buildApi } from '../model/Api';
import { isAuthentificated, login } from '../model/Identity';
import { getAppId, getToken, setToken } from '../model/TokenStore';

HubConnection.prototype.isStarted = function () {
    return this.connectionStarted;
}

const hubEvents = [
    'JoinStream', 'LeaveStream', 'ShareRooms', 'AddPeer', 'RemovePeer', 'IceCandidate', 'SessionDescription',
    'UpdateUser', 'SetUserActivityStatus',
    'ChatList', 'NewChat', 'LeaveChat', 'SaveChat',
    'Send', 'Delivered', 'Read', 'ReadSelf', 'UpdateMessage', 'DeleteMessage'
],
    listeners = {};

const buildEmpty = () => {
    try {
        return new HubConnectionBuilder().build();
    } catch (e) {
    }
}

const fire = (cmd, args) => {
    listeners[cmd] && listeners[cmd].forEach(l => l.apply(cmd, args));
};

var isStarting;

const getHubAppId = function () {
    return config.appId || getAppId();
}

const _authFetch = function () {
    if (config.authType) {
        var authType = (config.allowRefreshToken && getToken(getHubAppId())) ? 'RefreshToken' : (config.authType || 'Login');

        return login({ authType, authParams: config.authParams, appId: getHubAppId() })
            .then(x => x, promiseReconnect);
    }

    return buildApi(getHubAppId(), 'Auth/RefreshToken', 'POST')
        .then(x => x, promiseReconnect);
};

var authFetch = _authFetch;
var user;
var connection = buildEmpty();
const getConnection = function () {
    if (isStarting) {
        return isStarting;
    } else if (connection != null) {
        return Promise.resolve(connection);
    } else {
        return Promise.reject('connection is closed');
    }
},
    withConnection = function (ws, rest) {
        return getConnection().then(ws, e => {
            if (e === 'connection is closed') {
                return authFetch().then(auth =>
                    rest((url, options) => {
                        options = options || {};
                        var token = getToken(getHubAppId());
                        if (token) {
                            var hs = options.headers || new Headers();
                            hs.append ? hs.append('xauth', token) : (hs.xauth = token);

                            options.headers = hs;
                        }

                        return fetch((config.url || '') + '/api/' + url, options)
                            .then(x => x.json())
                            .then(x => x.errors ? Promise.reject(x.errors) : Promise.resolve(x.result));
                    }));
            }

            return Promise.reject(e);
        })
    }

const promiseReconnect = function (r) {
    // setTimeout(reconnect, 1000);
    if (getToken(getHubAppId())) {
        setToken(getHubAppId());

        return _authFetch();
    }
    else {
        return r && Promise.reject(r);
    }
}

function reconnect() {
    return isStarting = new Promise((connectResolve, connectReject) => {
        if (connection && connection.isStarted()) {
            return connection.stop().then(_ => reconnect().then(connectResolve, connectReject), connectReject);
        } else if (connection && connection.state === 'Connecting') {
            return connection.onreconnected(_ => reconnect().then(connectResolve, connectReject), connectReject);
        } else {
            return _authFetch().then(auth => {
                hub.endpoint = auth.endpoint || '';
                connection = new HubConnectionBuilder()
                    .withUrl(hub.endpoint + '/ws/' + getHubAppId() + '?xauth=' + auth.xauth, {
                        headers: {
                            xauth: getToken(getHubAppId())
                        }
                    })
                    .build();

                var authException;
                connection.onclose(e => {
                    connection = null;
                    if (e) {
                        fire('_disconnect', [e]);
                    } else if (authException) {
                        fire('_disconnect', authException);
                    }
                });

                connection.onreconnected(cid => {
                    fire('_connect', [cid]);
                });

                connection.onreconnecting(e => {
                    fire('_connecting', [e]);
                });

                connection.start().then(_ => getConnection().then(c => {
                    hubEvents.forEach(cmd => {
                        c.on(cmd, function () {
                            fire(cmd, arguments);
                        });
                    });

                    c.on('Auth', x => {
                        fire('_connect', [c.connectionId, x]);
                    });

                    /**
                    c.invoke('Auth', auth.xauth).then(x => {
                        if (x && x.id) {
                            user = x;
                            fire('_connect', [c.connectionId]);
                        } else {
                            authException = (x && x.errors) || ['cannot Auth'];
                            connection.stop();
                        }
                    });
                    /**/
                }));

                connectResolve(connection);
                isStarting = false;
            }, connectReject);
        }
    });
}

class Hub {
    constructor() {
        hubEvents.forEach(ev => {
            var l = 'on' + ev;
            if (!this[l]) {
                this[l] = fn => this.listen(ev, fn);
            }
        });
    }

    /** Configs -> /**/
    setUrl(url) {
        config.url = url;
        return this;
    }

    setAppId(appId) {
        config.appId = appId;
        return this;
    }

    setAuth(type, c) {
        config.authType = type;
        config.authParams = c;
        return this;
    }

    /** Connection -> /**/
    connect() {
        if (!connection) {
            return reconnect();
        }

        return Promise.resolve(connection);
    }

    disconnect() {
        if (connection) {
            var c = connection;
            connection = null;
            return c.stop();
        }

        return Promise.resolve();
    }

    /**
     * @param {String} cmd
     * @param {Function} fn
     * @returns {Function} unlistenFn
     */
    listen(cmd, fn) {
        var lc = listeners[cmd] || (listeners[cmd] = []);
        lc.push(fn);

        return () => (lc.indexOf(fn) >= 0) && lc.splice(lc.indexOf(fn), 1);
    }

    /** ACTIONS -> /**/
    /** User --> /**/
    /**
     * @param {UserActivityStatusDto} userActivityStatus
     */
    setUserActivityStatus(userActivityStatus) {
        return getConnection().then(x => x.invoke('SetUserActivityStatus', userActivityStatus));
    }

    /** ACTIONS -> /**/
    /** Messages --> /**/
    /**
     * @param {MessageDto} message
     */
    sendMessage(message) {
        return getConnection().then(x => x.invoke('Send', message));
    }

    /**
     * @param {MessageDto} message
     */
    updateMessage(message) {
        return getConnection().then(x => x.invoke('UpdateMessage', message));
    }

    messageList(chatId, count, lastId) {
        return getConnection().then(x => x.invoke('MessageList', chatId, count, lastId));
    }

    read(chatId, lastRead) {
        return getConnection().then(x => x.invoke('Read', chatId, lastRead));
    }

    deleteMessage(chatId, messageId) {
        return getConnection().then(x => x.invoke('DeleteMessage', chatId, messageId));
    }

    delivered(chatId, lastMessageId) {
        return getConnection().then(x => x.invoke('Delivered', chatId, lastMessageId));
    }

    messageStatus(messageId) {
        return getConnection().then(x => x.invoke('MessageStatus', messageId));
    }

    unreadedCount(chatId) {
        return withConnection(
            x => x.invoke('UnreadedCount', chatId),
            f => f('Message/UnreadedCount' + (chatId > 0 ? '?chatId=' + chatId : ''))
        );
    }

    /** Stream --> /**/
    /**
     */
    joinStream(chatId) {
        return getConnection().then(x => x.invoke('JoinStream', chatId));
    }

    leaveStream(chatId) {
        return getConnection().then(x => x.invoke('LeaveStream', chatId));
    }

    relaySDP(cid, sdp) {
        return getConnection().then(x => x.invoke('RelaySDP', { userId: cid, sessionDescription: sdp }));
    }

    relayICE(cid, sdp) {
        return getConnection().then(x => x.invoke('RelayICE', { userId: cid, iceCandidate: sdp }));
    }

    /** Chat --> /**/
    /**
     * @param {FetchParams} fetchParams
     */
    chatList(fetchParams) {
        return getConnection().then(x => x.invoke('FetchChats', fetchParams));
    }

    setPined(chatId, pined) {
        return getConnection().then(x => x.invoke('SetPined', chatId, pined));
    }

    setMuted(chatId, muted) {
        return getConnection().then(x => x.invoke('SetMuted', chatId, muted));
    }

    resolvePersonalChat(userId, clientId) {
        return getConnection().then(x => x.invoke('ResolvePersonalChat', userId, clientId));
    }

    findPersonalChat(userId, clientId) {
        return getConnection().then(x => x.invoke('FindPersonalChat', userId, clientId));
    }

    saveChat(chat) {
        return getConnection().then(x => x.invoke('SaveChat', chat));
    }

    updateMembers(chatId, addUserIds, removeUserIds) {
        return getConnection().then(x => x.invoke('UpdateMembers', { chatId, addUserIds, removeUserIds }));
    }

    updateAdministrators(chatId, addUserIds, removeUserIds) {
        return getConnection().then(x => x.invoke('UpdateAdministrators', { chatId, addUserIds, removeUserIds }));
    }

    getChat(chatId) {
        return getConnection().then(x => x.invoke('GetChat', chatId));
    }

    leaveChat(chatId) {
        return getConnection().then(x => x.invoke('LeaveChat', chatId));
    }

    deleteChat(chatId) {
        return getConnection().then(x => x.invoke('DeleteChat', chatId));
    }

    /** Files --> /**/
    getFileDownloadUrl(type, fileKey, fileName) {
        return getConnection().then(x => x.invoke('GetFileDownloadUrl', type, fileKey, fileName));
    }

    getFileUploadUrl(type, clientId) {
        return getConnection().then(x => x.invoke('GetFileUploadUrl', type, clientId));
    }

    
    makePreview(type, fileKey) {
        return getConnection().then(x => x.invoke('MakePreview', type, fileKey));
    }

    previewRootUrl(type) {
        return getConnection().then(x => x.invoke('PreviewRootUrl', type));
    }

    /** Notifications --> /**/
    /**
     * @param {string} token
     * @param {string} type FCM/Huawei
     * @param {string} clientName
     * @returns {boolean} success
     */
    registerToken(token, type, clientName) {
        return getConnection().then(x => x.invoke('RegisterToken', token, type, clientName));
    }

    /**
     * @param {string} token
     * @param {string} type FCM/Huawei
     * @param {string} clientName
     * @returns {boolean} success
     */
    attachToken(token, type, clientName) {
        return getConnection().then(x => x.invoke('AttachToken', token, type, clientName));
    }

    /**
     * @param {string} token
     * @param {string} type FCM/Huawei
     * @returns {boolean} success
     */
    deleteToken(token, type) {
        return getConnection().then(x => x.invoke('DeleteToken', token, type));
    }

    /** EVENTS --> /**/
    /**
     * @param {any} connection id
     */
    onConnect(fn) {
        return this.listen('_connect', fn);
    }

    /**
     * @param {any} error
     */
    onConnecting(fn) {
        return this.listen('_connecting', fn);
    }

    /**
     * @param {any} error
     */
    onDisconnect(fn) {
        return this.listen('_disconnect', fn);
    }

    /** Server Events --> /**/
    /**
     * @param {MessageDto} message
     */
    onMessage(fn) {
        return this.listen('Send', x => fn(new MessageSenderDto(new Message(x.message), x.attachment ? new Attachment(x.attachment) : null, x.sender)));
    }

    /**
     * @param {MessageDto} message
     */
    onMessageUpdated(fn) {
        return this.listen('UpdateMessage', x => fn(new MessageSenderDto(new Message(x.message), x.attachment ? new Attachment(x.attachment) : null, x.sender)));
    }

    /**
     * @param {MessageDto} deletedMessage
     */
    onMessageDeleted(fn) {
        return this.listen('DeleteMessage', fn);
    }

    /**
     * @param {bigint} chatId
     * @param {bigint} messageId
     */
    onMessageReceived(fn) {
        return this.listen('MessageReceived', fn);
    }

    /**
     * @param {bigint} chatId
     * @param {bigint} messageId
     */
    onMessageRead(fn) {
        return this.listen('Read', fn);
    }

    /**
     * @param {ChatDto} chat
     */
    onSaveChat(fn) {
        return this.listen('SaveChat', fn);
    }

    /**
     * @param {any} userUpdateInfo
     */
    onUpdateUser(fn) {
        return this.listen('UpdateUser', fn);
    }

    /**
     * @param {any} userActivityStatus
     */
    onSetUserActivityStatus(fn) {
        return this.listen('SetUserActivityStatus', fn);
    }
}

const hub = new Hub();

export default hub;