import React, { Component } from 'react';
import { saveChat } from "../../model/Model";
import { TextField } from '../Fields';

export class NewChat extends Component {
    state = {
        chatName: ''
    }

    constructor(p) {
        super(p);
        this.onCreateChat = this.onCreateChat.bind(this);
    }

    onCreateChat() {
        saveChat(this.state.chatName)
            .then(c => {
                debugger;
            });
    }

    render() {
        return <TextField
            placeholder="Chat name"
            value={this.state.chatName}
            onChange={e => this.setState({ chatName: e.target.value })}
            trailingIcon={{
                icon: 'add_comment',
                title: 'Create chat',
                tabIndex: 0,
                onClick: this.onCreateChat
            }} />;
    }
}