import route from "../Route";
import Api from "./Api";
import { listenUser } from "./Identity";
import Observer from "./Observer";
import { getAppId, getToken } from "./TokenStore";

const stores = {};

const appStore = [
    'Application'
];

export default class Store extends Observer {
    typeId
    data
    appId

    constructor(typeId) {
        super();

        this.typeId = typeId;
        this.data = [];
        this.appId = route.get('appId');
    }

    static get(typeId, autoLoad) {
        if (!stores[typeId]) {
            stores[typeId] = new Store(typeId);
        }

        if (autoLoad) {
            if (getToken()) {
                stores[typeId].load();
            } else {
                var l = listenUser((function () {
                    if (getToken()) {
                        stores[this].load();
                        l();
                    }
                }).bind(typeId));
            }
        }

        return stores[typeId];
    }

    listenRoute() {
        if (!this.routeListener) {
            this.routeListener = route.listen(r => {
                if (this.hasListeners('load')) {
                    var appId = getAppId();
                    if (this.appId != appId) {
                        this.onChangeAppId(appId);
                    }
                }
            });
        }
    }

    onChangeAppId(appId) {
        if (appId > 0) {
            this.appId = appId;
            if ((appStore.indexOf(this.typeId) < 0) || !this.appId) {
                this.load();
            }
        }
    }

    listen(e, fn) {
        if (e == 'load' && this.data && this.data.length) {
            fn(this.data);
        }

        this.listenRoute();

        return Observer.prototype.listen.apply(this, arguments);
    }

    load() {
        this.listenRoute();

        this.fire('loading', [this]);
        return Api[this.typeId].list().then(d => this.setData(d));
    }

    setData(d) {
        this.data.length = 0;
        d.forEach(x => this.data.push(x));
        this.fire('load', [this.data]);
    }

    save(ent) {
        var ind = this.data.findIndex(x => x.id == ent.id);
        if (ind >= 0) {
            this.data[ind] = ent;
        } else {
            this.data.push(ent);
        }

        this.fire('load', [this.data]);
    }
}

const appStores = {};
export class ApplicationStore extends Store {
    constructor(typeId, appId) {
        super(typeId);

        this.appId = appId;
    }

    onChangeAppId() {
    }

    static get(appId, typeId, autoLoad) {
        var appStore = appStores[appId];
        if (!appStore) {
            appStores[appId] = appStore = {};
        }

        var typeStore = appStore[typeId];
        if (!appStore[typeId]) {
            appStore[typeId] = typeStore = new Store(typeId);
        }

        if (autoLoad) {
            if (getToken()) {
                typeStore.load();
            } else {
                var l = listenUser((function () {
                    if (getToken()) {
                        typeStore.load();
                        l();
                    }
                }).bind(typeId));
            }
        }

        return typeStore;
    }
}