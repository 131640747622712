import errorManager from "./ErrorManager";
import { getAppId, getToken, getUrl } from "./TokenStore";

const getBaseUrl = appId => getUrl(appId) || '';

export const getHeaders = appId => {
    var xauth = getToken(appId),
        headers = {
            'Content-Type': 'application/json'
        };

    if (xauth) {
        headers.xauth = xauth;
    }

    return headers;
}

const buildRequest = (appId, path, method, body, params) => {
    var ff = () => fetch(getBaseUrl(appId) + path + (params || ''), {
        method: method,
        headers: getHeaders(appId),
        body: body && JSON.stringify(body)
    });

    return ff()
        .then(x => x.json())
        .then(x => {
            if (x.errors) {
                // TODO: listeners + tokenexception -> appin
                var result = errorManager.fireErrors(ff, x.errors);
                if (result) {
                    return result;
                }
            }

            return x.result;
        });
}

export const buildRest = (appId, path, method, body, params) => buildRequest(appId, '/rest/' + getAppId(appId) + '/' + path, method, body, params);
export const buildApi = (appId, path, method, body, params) => buildRequest(appId, '/api/' + getAppId(appId) + '/' + path, method, body, params);

const buildCRUD = typeId => {
    return {
        list: appId => buildRest(appId, typeId, 'GET'),
        get: (appId, id) => buildRest(appId, typeId, 'GET', null, '/' + id),
        create: (appId, entity) => buildRest(appId, typeId, 'POST', entity),
        update: (appId, entity) => buildRest(appId, typeId, 'PUT', entity, '/' + entity.id),
        delete: (appId, id) => buildRest(appId, typeId, 'DELETE', null, '/' + id)
    }
}

const api = {
    Auth: {
        Current: appId => buildApi(appId, 'Auth/Current', 'GET')
    }
};

export default api;

export const buildTypeCRUD = typeId => api[typeId] = buildCRUD(typeId);