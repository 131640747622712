import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported, getToken, onMessage } from 'firebase/messaging';

var hub;

export const startNotifications = function () {
    const firebaseConfig = {
        apiKey: "AIzaSyAt6rLN4HInAbwxYDCT0vxB6MPzPckoCL0",
        authDomain: "dscontrol-prod.firebaseapp.com",
        databaseURL: "https://dscontrol-prod.firebaseio.com",
        projectId: "dscontrol-prod",
        storageBucket: "dscontrol-prod.appspot.com",
        messagingSenderId: "765248762082",
        appId: "1:765248762082:web:c121801953b8df0cc5aebb",
        measurementId: "G-XRR94J07PB",
        vapidKey: 'BM-CKF49OBusC2IOHIB9G2VwluSljXg5CX8akMk97VLR5MOK_LgJa4bIiPnFcARAK4vfcH5cfLXZ8Nyt8jXkfnU'
    };

    if (window.IntragramFCMConfig) {
        Object.apply(firebaseConfig, window.IntragramFCMConfig);
    }

    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');

            const app = initializeApp(firebaseConfig);
            const messaging = getMessaging(app);
            getToken(messaging, { vapidKey: firebaseConfig.vapidKey })
                .then((currentToken) => {
                    if (currentToken) {
                        onMessage(messaging, payload => {
                            if (window.IntragramFCMConfig && window.IntragramFCMConfig.showNotifications && payload.notification) {
                                new Notification(payload.notification.title, payload.notification);
                            }
                        });

                        hub.registerToken(currentToken, 'FCM')
                            .then(x => {
                            });
                    } else {
                        // Show permission request.
                        console.log('No Instance ID token available. Request permission to generate one.');
                        // Show permission UI.
                        //updateUIForPushPermissionRequired();
                        //setTokenSentToServer(false);
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                    //showToken('Error retrieving Instance ID token. ', err);
                    //setTokenSentToServer(false);
                });
        } else {
            console.log('Unable to get permission to notify.');
        }
    });
}

export const applyHub = function (_hub) {
    hub = _hub;
    var iss = isSupported();
    if (iss) {
        iss.then(x => {
            if (x) {
                _hub.onConnect(startNotifications);
            } else {
                console.log('FCM Push not supported in this browser');
                return;
            }
        });
    }

    // _hub.onDisconnect(stopNotifications);
}