import BaseEntity from "./BaseEntity";

export default class User extends BaseEntity {
    static _typeId = 'User'
    static fields = [{
        name: 'clientId',
        title: 'Client ID'
    }, {
        name: 'name',
        title: 'Name'
    }, {
        name: 'email',
        title: 'Email'
    }, {
        name: 'avatarUrl',
        title: 'Avatar Url'
    }, {
        name: 'password',
        hideInList: true,
        title: 'Пароль'
    }]

    name
    clientId
    avatarUrl
}

BaseEntity.registerType(User);