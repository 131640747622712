import '@rmwc/button/styles';
import '@rmwc/card/styles';
import '@rmwc/drawer/styles';
import '@rmwc/icon/styles';
import '@rmwc/list/styles';
import '@rmwc/menu/styles';
import '@rmwc/textfield/styles';
import '@rmwc/checkbox/styles';
import '@rmwc/top-app-bar/styles';
import '@rmwc/data-table/styles';
import '@rmwc/circular-progress/styles';
import '@rmwc/linear-progress/styles';
import '@rmwc/typography/styles';
import '@rmwc/fab/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import './Startup'
import App from './App';
import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));