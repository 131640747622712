import Route from './Route'
import Hub from './utils/Hub';
import { applyHub as applyModelHub } from './model/Model';
import { applyHub as applyNotifierHub } from './utils/Notifier';
import { fetchUser } from './model/Identity';
import { getAppId, setAppIdResolver } from './model/TokenStore';
import config from './Config';

Route.listenDocument();

setAppIdResolver(() => Route.get('appId'));

window.IntragramFCMConfig = {
    showNotifications: true
};

applyModelHub(Hub);
applyNotifierHub(Hub);

Route.register('page', _ => 'overview');
Route.register('subpage');
Route.register('appId');
Route.register('id', null, null, x => parseInt(x) == x ? parseInt(x) : x);
Route.register('tab');

Route.addRoute('')
    .addUrlPath('page')
    .addUrlPath('id')
    .addUrlPath('subpage')
    .addUrlPath('tab');

Route.hashChanged();

config.allowRefreshToken = true;

(appId => {
    if (appId) {
        fetchUser(appId);
    }

    fetchUser(0);
})(getAppId())