import 'moment-timezone';
import React from 'react';
import Hub from "../../utils/Hub";
import AppIdComponent from "../AppIdComponent";
import Chat from "./Chat";
import ChatList from "./ChatList";
import './message.css';

const islocal = document.location.hostname == 'localhost',
    urlParts = document.location.search.substr(1).split('&').map(x => x.split('=')),
    authParams = {
        "clientId": urlParts.filter(x => x[0] == 'name' || x[0] == 'clientId').map(x => x[1])[0],
        "login": urlParts.filter(x => x[0] == 'login').map(x => x[1])[0],
        "token": urlParts.filter(x => x[0] == 'token').map(x => x[1])[0] || "tokentoken"
    };

Hub.setUrl(islocal ? 'https://localhost:5004' : '')
    .setAuth(authParams.login ? 'Login' : 'Token', authParams);

export default class Messanger extends AppIdComponent {
    state = {
        chatId: null,
        newChatName: ''
    }

    constructor(p) {
        super(p);
    }

    applyAppId() {
        Hub.disconnect();
        Hub.connect();
    }

    componentWillUnmount() {
        AppIdComponent.prototype.componentWillUnmount.apply(this, arguments);
        Hub.disconnect();
    }

    buildChatList() {
        return <ChatList ref={x => this.chatList = x} onSelect={x => {
            this.setState({ chatId: x.chat.id, name: x.displayName });
        }} />;
    }

    buildChat() {
        if (this.state.chatId) {
            return <div className="chat-wrapper">
                <Chat key={this.state.chatId} chatId={this.state.chatId} onClose={() => {
                    this.chatList.setChatId();
                    this.setState({ chatId: null });
                }} />
            </div>;
        }
    }

    render() {
        return <div className="messanger">
            {this.buildChatList()}
            {this.buildChat()}
        </div>;
    }
}