import { Button } from '@rmwc/button';
import { Checkbox } from '@rmwc/checkbox';
import { DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableHeadCell, DataTableRow } from '@rmwc/data-table';
import { LinearProgress } from '@rmwc/linear-progress';
import { TextField } from '@rmwc/textfield';
import React from 'react';
import BaseEntity from '../entities/BaseEntity';
import Store from "../model/Store";
import route from "../Route";
import AppIdComponent from './AppIdComponent';

export default class List extends AppIdComponent {
    checked
    _searchFn
    _type

    state = {
        loading: false,
        page: 1,
        limit: 10,
        search: '',
        data: []
    }

    constructor(p) {
        super(p);

        this.checked = [];

        this.setChecked = this.setChecked.bind(this);
        this.onCreateClick = this.onCreateClick.bind(this);

        this.applyType();
        this.applySearchFn();
    }

    getSearchFields() {
        return this.props.searchFields;
    }

    getTypeId() {
        return this.props.typeId;
    }

    applyType() {
        this._type = BaseEntity.getType(this.getTypeId());
    }

    applySearchFn() {
        if (this.props.searchFields) {
            this._searchFn = (entity, search) => this.props.searchFields
                .find(sf => entity[sf] && entity[sf].toLowerCase().indexOf(search) >= 0);
        }
    }

    applyAppId() {
        this.store = Store.get(this.getTypeId(), true);

        this.unlisten.push(this.store.listen('loading', () => this.setState({ loading: true })));
        this.unlisten.push(this.store.listen('load', data => this.setState({ data, loading: false })));
    }

    setChecked(e) {
    }

    getData() {
        var d = this.state.data;
        if (this.state.search) {
            let s = this.state.search.toLowerCase();
            d = d.filter(x => this._searchFn(x, s));
        }

        var s = (this.state.page - 1) * this.state.limit;
        return d.slice(s, s + this.state.limit);
    }

    getForm() {
        return this.props.form ? React.create(this.props.form) : null;
    }

    buildValue(row, field) {
        return row[field.name];
    }

    buildRowCells(routePrefix, row, i) {
        return this._type.fields.filter(x => !x.hideInList)
            .map(x => <DataTableCell key={x.name}>
                <a href={routePrefix + row.id}>{this.buildValue(row, x)}</a>
            </DataTableCell>);
    }

    buildTable() {
        var routePrefix = route.prefix + '/' + route.get('page') + '/';

        return <div className="card container">
            <DataTable stickyRows={1} style={{ minWidth: 640, maxWidth: '100%' }}>
                <DataTableContent>
                    <DataTableHead>
                        <DataTableRow>
                            <DataTableHeadCell hasFormControl>
                                <Checkbox />
                            </DataTableHeadCell>
                            {this._type.fields.filter(x => !x.hideInList).map(x => <DataTableHeadCell key={x.name}>{x.title}</DataTableHeadCell>)}
                        </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                        {this.state.loading ||
                            this.getData().map((row, i) => <DataTableRow key={row.id} data-route={'id=' + row.id}>
                                <DataTableCell hasFormControl data-route="">
                                    <Checkbox checked={this.checked[i]} onChange={this.setChecked} />
                                </DataTableCell>
                                {this.buildRowCells(routePrefix, row, i)}
                            </DataTableRow>)}
                    </DataTableBody>
                </DataTableContent>
                {this.state.loading && <LinearProgress />}
            </DataTable>
        </div>;
    }

    renderSearch() {
        if (this._searchFn) {
            return <TextField
                icon="search"
                outlined={true}
                label="search"
                trailingIcon={this.state.search ? { icon: 'close', onClick: e => this.setState({ search: '' }) } : null}
                onChange={e => this.setState({ search: e.target.value })}
                value={this.state.search} />;
        }
    }

    onCreateClick() {
        route.setState('id', 'new');
    }

    buildTopBar() {
        return [
            this.renderSearch(),
            <div className="f1" />,
            <Button label="Create" icon="add" raised onClick={this.onCreateClick} />
        ];
    }

    render() {
        return <div className="d-flex flex-row entity-editor">
            <div className="d-flex flex-col">
                {/** top bar/**/}
                <div className="container d-flex flex-row flex-center">
                    {this.buildTopBar()}
                </div>

                {this.buildTable()}

                {/** bootom bar /**/}
            </div>
            {route.get('id') ? this.getForm() : null}
        </div>;
    }
}